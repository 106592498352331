import React from 'react'
import { Card } from 'components/primitives'
import Link from './core/Link'
import { useStaticQuery, graphql } from 'gatsby'

export default props => {
  const {
    allAirtable: { edges }
  } = useStaticQuery(graphql`
    {
      allAirtable(filter: { table: { eq: "모음전" } }) {
        edges {
          node {
            data {
              handle
              name
            }
          }
        }
      }
    }
  `)
  const themes = edges.map(({ node }) => node.data)

  return (
    <Card
      bg='lightGray'
      py={2}
      {...props}
      display='flex'
      justifyContent='center'
      borderBottom='1px solid'
      borderColor='muted'
    >
      {themes.map((theme, index) => (
        <Link
          key={theme.handle}
          fontWeight='bold'
          color='lightText'
          mr={themes.length - 1 !== index ? 4 : 0}
          to={`/themes/${theme.handle}`}
        >
          {theme.name}
        </Link>
      ))}
    </Card>
  )
}
