import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import ThemeNav from 'components/theme-nav'
import { fullContainer, container } from 'components/core/Layout'
import { Box } from 'components/primitives'
import Heading from 'components/core/Heading'
import Text from 'components/core/Text'
import Image from 'components/core/Image'
import { Row, Column } from 'components/core/Layout'
import Gallery from 'react-photo-gallery'
import { ProductCard } from 'screens/product/ProductList'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default ({
  data: {
    airtable: { data }
  },
  ...props
}) => {
  const photos = data.mood_images.map(({ data }) => {
    return {
      src: data.images[0].thumbnails.large.url,
      width: data.images[0].thumbnails.large.width,
      height: data.images[0].thumbnails.large.height
    }
  })
  const matches = useMediaQuery('(min-width:640px)')
  const products = data.products.map(p => p.data)

  return (
    <Layout>
      <Head title={data.name} />
      <ThemeNav />
      <Box pt={[4, 5]}>
        <Row mb={[3, 4]} {...container}>
          <Column mb={4} width={[1, null, 2 / 3]}>
            <Image src={data.images[0].url} />
          </Column>
          <Column mb={4} width={[1, null, 1 / 3]}>
            <Heading fontSize={5} fontWeight='heavy' mb={2}>
              {data.name}
            </Heading>
            <Text fontSize={3}>{data.description}</Text>
          </Column>
        </Row>
        <Box mb={[4, 5]} py={5} bg='lightGray' {...fullContainer}>
          <Gallery photos={photos} direction={matches ? 'row' : 'column'} />
        </Box>
        <Box {...container}>
          <Heading mb={3} fontWeight='heavy'>
            이 모음전에 소개된 자재
          </Heading>
          <Row mx={-1} {...props}>
            {products.map(product => (
              <Column
                key={product.handle}
                px={1}
                width={[1 / 2, 1 / 3, 1 / 4, 1 / 5, 1 / 6]}
              >
                <ProductCard key={product.handle} product={product} />
              </Column>
            ))}
          </Row>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query Theme($handle: String!) {
    airtable(table: { eq: "모음전" }, data: { handle: { eq: $handle } }) {
      data {
        name
        handle
        description
        products {
          data {
            name
            images {
              id
              url
              filename
              size
              type
              thumbnails {
                small {
                  url
                  width
                  height
                }
                large {
                  url
                  width
                  height
                }
                full {
                  url
                  width
                  height
                }
              }
            }
            type
            tile_type
            colors
            tags
            surface
            width
            length
            derived_size
            coverage
            derived_coverage
            price_per_coverage
            price_per_coverage_without_tax
            price_per_box
            pieces_per_box
            has_samples
            series
            series_ko
            series_variant
            handle
            handle_ko
            description_series
            description
            video_url
            shop_url
            created_at
            updated_at
          }
        }
        mood_images {
          data {
            handle
            images {
              id
              url
              filename
              size
              type
              thumbnails {
                small {
                  url
                  width
                  height
                }
                large {
                  url
                  width
                  height
                }
                full {
                  url
                  width
                  height
                }
              }
            }
          }
        }
        images {
          id
          url
          filename
          size
          type
          thumbnails {
            small {
              url
              width
              height
            }
            large {
              url
              width
              height
            }
            full {
              url
              width
              height
            }
          }
        }
      }
    }
  }
`
